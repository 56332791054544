import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { displayDateFormat, backendDateFormat, fieldDateFormat } from '../../../../constants'
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class MaintenanceInvoiceItem extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { type, value, invoiceInfo, invoice_item, index, validationArray, isJac } = this.props;
    if (type === 'view') {
      return (
        <tr>
          <td>
            <h6 className="para-ui">
              {value.invoice_item_type_id.name}
            </h6>
          </td>
          <td className="invoice-description-block">
            <h6 className="para-ui"> {value.description}</h6>
          </td>
          <td>
            <h6 className="para-ui"> {value.unit_type}</h6>
          </td>
          <td>
            <h6 className="para-ui">
              {value.no_of_unit}
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {value.rate_per_unit}
              </NumericLabel>
            </h6>
          </td>
          {
            isJac ?
              null :
              <td>
                {value.tax_rate}
              </td>
          }
          {
            isJac ?
              null :
              <td>
                <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                  {parseFloat(value.tax_amount).toFixed(2)}
                </NumericLabel>
              </td>
          }
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {value.sub_total}
              </NumericLabel>
            </h6>
          </td>
        </tr>
      )
    } else {
      return (
        <tr>
          <td style={{ width: '125px' }}>
            <h6 className="para-ui">
              {invoice_item.invoice_item_type_id.name}
            </h6>
          </td>
          <td className="invoice-description-block">
            <textarea onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'description', index)}>{invoice_item?.description || ''}</textarea>
          </td>
          <td>
            <h6 className="para-ui"> {invoice_item.unit_type}</h6>
          </td>
          <td>
            <input type="text" defaultValue={invoice_item.no_of_unit}
              onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'no_of_unit', index, invoice_item.invoice_item_type_id.name, invoice_item.unit_type)}
            />
            {validationArray.length > 0 ?
              validationArray[index].no_of_unit == false ?
                <h6 className="error-msg">Please enter valid values</h6> : null : null}
          </td>
          <td>
            <input type="text" value={invoice_item.rate_per_unit}
              onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'rate_per_unit', index)}
            />
            {validationArray.length > 0 ?
              validationArray[index].rate_per_unit == false ?
                <h6 className="error-msg">Please enter valid values</h6> : null : null}
          </td>
          {
            isJac ?
              null :
              <td style={{ width: '60px' }}>
                <input type="text" value={invoice_item.tax_rate}
                  onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'tax_rate', index)}
                />
                {validationArray.length > 0 ?
                  validationArray[index].tax_rate == false ?
                    <h6 className="error-msg">Please enter valid value</h6> : null : null}
              </td>}
          {
            isJac ?
              null :
              <td>
                <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>{invoice_item.tax_amount}</NumericLabel>
              </td>}
          <td>
            <input type="text" value={invoice_item.sub_total}
              onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'sub_total', index)}
            />
          </td>
        </tr>
      )
    }
  }
}
