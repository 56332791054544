import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import FilterBar from '../../../../shared/FilterBar';
import { EditSidebar, Header, ShortHead, ContractSideBar, FormModeType, ContractBarLoader } from '../../Elements';
import Select from 'react-select';
import { float2DecRegx } from '../../../../constants';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import { checkString } from '../../../../formValidator';
import Pagination from "react-js-pagination";
import UtilizationRow from '../components/UtilizationRow';
import UtilizationList from '../components/UtilizationList';
import UtilsListInfo from '../components/UtilsListInfo';
import UtilsList from '../components/UtilsList';
import RentalList from '../components/RentalList';
import UtilsGraph from '../components/UtilsGraph';
import EditUtils from '../components/EditUtils';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { sExportContractsAc } from '../../../../shared/actionCreators';
import {  globalPostService, globalPutService } from '../../../../globalServices';
import { assetsType } from '../../../../constants';
import { UtilsAssetViewAc, editUtilsInfoAc, utilsListAc, exportUtilizationAc, exportRentalUtilizationAc, utilFnCrudAc, getRentalUtilizationAc, utilsTrendsAc, utilsAvgAc, utilsInitiateAc, utilsMajorAssemblyAc } from '../actionCreators';
import { removeEmptyKey, getLocalStorageInfo, toastFlashMessage } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import { CHANGE_UTIL_GRAPH, CLOSE_UTILS_BAR, TOGGLE_VIEW_EDIT, UTIL_CRUD_OPERN, TOGGLE_TAB } from '../actions';
import '../../../maintenance/MRFinanceGraph/assets/styles/CashFlow.scss';
import ExportUtilSummery from '../components/ExportUtilSummery';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import UtilTrendsGraph from '../../shared';
import { DropzoneArea } from 'material-ui-dropzone';
import {getUtilsAttachmentApi, getRentalUtilsAttachmentApi} from '../apiServices'
import { contrHdlineInfoAc } from '../../HeadlinesInfo/actionCreators';

const months = [
  { label: 'Jan', value: '01' },
  { label: 'Feb', value: '02' },
  { label: 'Mar', value: '03' },
  { label: 'Apr', value: '04' },
  { label: 'May', value: '05' },
  { label: 'Jun', value: '06' },
  { label: 'Jul', value: '07' },
  { label: 'Aug', value: '08' },
  { label: 'Sep', value: '09' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' }
];
class Utilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editUtils: false,
      saveing: false,
      activeTab: '0',
      activeRental: 0,
      appliedFilter: {},
      error: {},
      page: 1,
      rentalPage: 1,
      year: '',
      months: months,
      monthsss: months,
      month: '',
      addUtil: false,
      utilsObj: {
        asset_location: "",
        hours: 1,
        cycles: 3,
        block_hour: 27109.00,
        remarks: "Nik"
      },
      editable: false,
      attachedDocList: [],
      attachedRentalDocList:[],
    }
    this.toggle = this.toggle.bind(this);
    this.getUtilsAttachmentApi = getUtilsAttachmentApi.bind(this)
    this.getRentalUtilsAttachmentApi = getRentalUtilsAttachmentApi.bind(this)
  }
  componentDidMount() {
    this.props.fetchUtilsInfo('list');
    this.props.utilizationList({});
    this.props.utilsTrends();
    this.props.fetchContractInfo();
    this.props.utilsAvg();
    this.props.utilsInitiateFn();
    this.props.getRentalUtilization();
    this.props.utilsMajorAssemblyFn();
    this.getUtilsAttachmentApi(this.props?.params?.contract_slug ? this.props.params.contract_slug: null);
    this.getRentalUtilsAttachmentApi(this.props?.params?.contract_slug ? this.props.params.contract_slug: null);
  }

  saveRentalUtils = () => {
    const { utilsObj, month, year } = this.state;
    var validateNewInput = {
      year: checkString({ value: year, required: true, minLength: '', maxLength: '', message: 'Please select Year' }),
      month: checkString({ value: month, required: true, minLength: '', maxLength: '', message: 'Please select Month' }),
      hours: parseFloat(utilsObj.hours) >= 0 ? '' : 'Please enter Flight Hours',
      cycles: parseInt(utilsObj.cycles) >= 0 ? '' : 'Please enter Flight cycles',
      // block_hour:parseFloat(utilsObj.block_hour) >= 0 && parseFloat(utilsObj.block_hour) <= parseFloat(utilsObj.hours) ? 'Block Hours should be greater than or equal to Flight Hours' : ''

    };
    if (this.props.ctrShortInfo.contract && this.props.ctrShortInfo.contract.unit_of_measure === 4) {
      validateNewInput = {
        ...validateNewInput,
        // block_hour: parseFloat(utilsObj.block_hour) > 0 && parseFloat(utilsObj.block_hour) <= parseFloat(utilsObj.hours) ? 'Block Hours should be greater than or equal to Flight Hours' : '',
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if (this.state.utilsObj.type === 'edit') {
        let data = this.state.utilsObj
        data = {
          ...data,
          year: this.state.year,
          month: this.state.month,
          asset_type: this.props.ctrShortInfo.asset_type
        }
        this.setState({ saving: true })
        globalPutService(`/contracts/${this.props.params.contract_slug}/rental-utilization/${this.state.utilsObj.id}/`, data)
          .then(response => {
            this.setState({ saving: false })
            if (response.data.statusCode === 200) {
              this.setState({ addUtil: false, utilsObj: {}, month: '', year: '' })
              this.props.getRentalUtilization(this.props.params.contract_slug, { page: 1 }, true)
              toastFlashMessage(response.data.message, 'success')
            }
          })
      } else {
        let data = {
          year: this.state.year,
          month: this.state.month,
          assets: [
            {
              asset_id: this.props.ctrShortInfo.id,
              hours: this.state.utilsObj.hours,
              cycles: this.state.utilsObj.cycles,
              block_hour: this.state.utilsObj.block_hour,
              asset_location: this.state.utilsObj.asset_location,
              remarks: this.state.utilsObj.remarks,
              asset_type: this.props.ctrShortInfo.asset_type
            }
          ]
        }
        this.setState({ saving: true })
        globalPostService(`/contracts/${this.props.params.contract_slug}/rental-utilization/`, data)
          .then(response => {
            this.setState({ saving: false })
            if (response.data.statusCode === 200) {
              this.setState({ addUtil: false, utilsObj: {}, month: '', year: '' })
              toastFlashMessage(response.data.message, 'success')
              this.props.getRentalUtilization(this.props.params.contract_slug, { page: 1 }, true)
            }
          })
      }
    } else {
      this.setState({
        error: validateNewInput
      })
    }
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
        [keyParam]:value
    }))
  }
  updateUtilField = (value, key) => {
    if (['hours', 'block_hour'].includes(key)) {
      if (float2DecRegx.test(value)) {
        let error = this.state.error
        delete error[key]
        this.setState({
          utilsObj: {
            ...this.state.utilsObj,
            [key]: value
          },
          error
        })
      }
    } else if (key === 'cycles') {
      if (float2DecRegx.test(value)) {
        let error = this.state.error
        delete error.cycles
        this.setState({
          utilsObj: {
            ...this.state.utilsObj,
            [key]: value
          },
          error
        })
      } else if (value === '' || value === null) {
        let error = this.state.error
        delete error.cycles
        this.setState({
          utilsObj: {
            ...this.state.utilsObj,
            [key]: value
          },
          error
        })
      }
    } else {
      let error = this.state.error
      delete error[key]
      this.setState({
        utilsObj: {
          ...this.state.utilsObj,
          [key]: value
        },
        error
      })
    }
  }
  yearMonth = (value, type) => {
    let month = []
    if (type == 'year') {
      if(this.props.utilsInitiate.lease_duration_type != 1 && this.props.rentalUtils.utilization.length == 0 ){
        month.push({ label: moment(this.props.rentalUtils?.rental_months[0]).format('MMM'), value: moment(this.props.rentalUtils?.rental_months[0]).format('MM')})
        this.setState({
          year: value,
           months: month
        })
      }
      else{
        this.props.rentalUtils.rental_months.filter(year => moment(year).format('YYYY') === value).map(mnth => {
          month.push({ label: moment(mnth).format('MMM'), value: moment(mnth).format('MM') })
        })
        this.setState({
          year: value,
          months: month
        })
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        month: value
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleSidebar = (type) => {
    this.setState(prevState => ({
      [type]: !prevState[type]
    }))
  }

  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    if (this.state.activeRental === 1) {
      this.props.getRentalUtilization(this.props.params.contract_slug, getQueryStr);
    } else {
      this.props.utilizationList(getQueryStr);
    }
    this.setState({
      appliedFilter: filterParams,
      page: 1
    });
  }
  changeFilterFn = (...args) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'major_assembly':
        if (args[1]) {
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Major Assembly Account: ', value: args[1].name,
              asset_id: args[1].asset_id,
              asset_type: args[1].asset_type
            }
          }
        }
        break;
      case 'date':
        let date = (args[1] ? args[1] : '--') + ' To ' + (args[2] ? args[2] : '--');
        filterParams = {
          ...filterParams,
          [args[0]]: {
            name: 'Date',
            value: date,
            startDate: args[1] ? args[1] : '',
            endDate: args[2] ? args[2] : ''
          }
        }
      default:
        break;
    }
    this.setState({
      appliedFilter: filterParams,
      selectedFilterTitle: '',
      page: 1
    });

    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    if (this.state.activeRental === 1) {
      this.props.getRentalUtilization(getQueryStr);
    } else {
      this.props.utilizationList(getQueryStr);
    }
  }

  utildata = (slug, data, type) => {
    this.setState({
      editable: data.editable
    });
    this.props.utilFnCrud(slug, data, type);
  }

  getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if (key == 'date') {
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      } else if (key == 'major_assembly') {
        queryFilter['asset_id'] = params[key].asset_id
        queryFilter['asset_type'] = params[key].asset_type
      } else {
        queryFilter[key] = params[key].filterData
      }
    });
    return queryFilter;
  }
  handlePageChange = (page) => {
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter));
    getQueryStr['page'] = page;
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
    this.props.utilizationList({ page : page});
  }
  render() {
    const { ctrShortInfo, rentalUtils, utilsListInfo, utlisAverage, utilsInitiate, utilsLoader, utilsMajorAssembly, utilCrud, activeTab, contractInfo } = this.props;
    const monthsss = this.state.monthsss
    const years = [], months = [];
    if(this.props.utilsInitiate.lease_duration_type != 1 && this.props.rentalUtils.utilization.length == 0 ){
      years.push({label:moment(this.props.rentalUtils.rental_months[0]).format('YYYY'), value: moment(this.props.rentalUtils.rental_months[0]).format('YYYY')})
      }
      else{
        rentalUtils.rental_months.map(yr => {
          if (years.filter(year => moment(yr).format('YYYY') === year.value).length === 0) {
            years.push({ label: moment(yr).format('YYYY'), value: moment(yr).format('YYYY') })
          }
        })
      }
    const filterMenu = {
      'Major Assembly': {
        'inputType': 'dropdown',
        'keyParam': 'major_assembly',
        'placeholder': 'Select Major Assembly',
        'options': utilsMajorAssembly,
        'labelKey': 'name',
        'valueKey': 'id',
      },
      'Date': {
        'inputType': 'date',
        'keyParam': 'date'
      }
    };
    const rentalFilterMenu = {
      'Date': {
        'inputType': 'date',
        'keyParam': 'date'
      }
    };
    return (
      <div className="contract-container" style={{height:'auto'}}>
        <ContractSideBar application={`Utilization`} type="brief" params={this.props.params} />
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        {utilsLoader ? <ListLoader /> : null}
        <div className="util-heading-block" style={{marginLeft:'60px'}}>
          <UtilTrendsGraph/>
        </div>
        { utlisAverage && utlisAverage.length ?
            <div className="utilization-table-block">
              <h5 className="uti-heading" style={{ padding: "15px 0", background: "#f0f2f3" }}>Utilization Summary
                { checkPermission('contracts', 'utilization', 'EXP') ?
                  <ExportUtilSummery util_type="utilization_summary" contract={ctrShortInfo.contract} ctrShortInfo={ctrShortInfo} exportUtils={(contract, type, util_type, aircraft, ctrShortInfo) => this.props.exportUtilsFile(contract, type, util_type, aircraft, removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)), ctrShortInfo)} aircraft={ctrShortInfo} />:null
                }
              </h5>
              <div className="utilization-wrapper">
                <table>
                  <UtilizationRow />
                  <tbody>
                    { utlisAverage.map((utilAv, index) => 
                      <UtilizationList key={index} utilAv={utilAv} />
                    )}
                  </tbody>
                </table>
              </div>
            </div> : null
        }
        <div className="edit-view-tech-spec" style={{ marginTop: '40px', marginLeft: '60px' }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeRental === 0 })}
                onClick={() => {this.setState({ activeRental: 0, error: {} }); this.props.utilizationList({});}}
              >
                Monthly Utilization
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeRental === 1 })}
                onClick={() => this.setState({ activeRental: 1, error: {} })}
              >
                Rental Utilization
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="util-heading-block" style={{marginLeft:'60px'}}>
          <TabContent activeTab={this.state.activeRental}>
            <TabPane tabId={0}>
              <div style={{margin:'0'}} className="cash-flow-wrapper" >
                <div className="utilization-outer-wrapper ">
                  <h5 className="para uti-heading flex-centered" style={{ position: "relative" }} >
                    MR Utilization History
                    {checkPermission('contracts', 'utilization', 'C') && Object.keys(utilsInitiate).length && contractInfo?.hdlineInfo?.archived_asset ==false?
                      <span style={{ position: "absolute", top: "0px", left: "405px" }}>
                        {moment(utilsInitiate.utilization.start_month).format('YYYYMM') == moment().format('YYYYMM') ?
                          utilsInitiate.utilization.is_contract_start_date ?
                            <Link to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/${this.props.params.contract_slug}/utilization/add`} className="primary-btn caption-ui" style={{ height: "27px", width: "141px" }}>Add Utilization</Link> : 
                            <Link className="disabled-btn caption-ui" style={{ height: "28px", width: "135px", cursor:"not-allowed", background:"#ddd", textAlign:"center", borderRadius:"3px" }}>Add Utilization</Link> :
                          <Link to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/${this.props.params.contract_slug}/utilization/add`} className="primary-btn caption-ui" style={{ height: "27px", width: "141px" }}>Add Utilization</Link>
                        }
                      </span> : null
                    }
                    {checkPermission('contracts', 'utilization', 'EXP') ?
                      <ExportUtilSummery util_type="utilization_history" contract={ctrShortInfo.contract} ctrShortInfo={ctrShortInfo} exportUtils={(contract, type, util_type, aircraft, ctrShortInfo) => this.props.exportUtilsFile(contract, type, util_type, aircraft, removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)), ctrShortInfo)} aircraft={ctrShortInfo} />
                      : null
                    }
                  </h5>
                </div>
                <div className="asset-list-filter-sort">
                  <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={filterMenu} changeFilterFn={this.changeFilterFn} />
                </div>
                <UtilsListInfo>
                  {utilsListInfo.list.map((utils, index) =>
                    <UtilsList isEditAble={utils.editable} contractInfo={contractInfo} getUtilsAttachmentApi={this.getUtilsAttachmentApi} attachedDocList={this.state.attachedDocList} props={this.props} editUtils={(mode) => this.utildata(this.props.params.contract_slug, utils, utils.editable ? mode : 'view')} key={index} utils={utils} utilLists={utilsListInfo.list} index={index}  />)}
                </UtilsListInfo>
                {
                  !utilsLoader && utilsListInfo.list && !utilsListInfo.list.length ? <NoRecordFound style={{ marginLeft: '0px' }} description="Get Started by clicking 'Add Utilization' button above" /> : null
                }
                {
                  !utilsLoader && !utilsListInfo.list.length ? null :
                    <div style={{ padding: '0 0 40px 0' }}>
                      <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={20}
                        totalItemsCount={utilsListInfo.pagination.total ? utilsListInfo.pagination.total : 0}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}

                      />
                    </div>
                }
              </div>
            </TabPane>
            <TabPane tabId={1}>
              <div style={{margin:'0'}} className="cash-flow-wrapper" >
                <div className="utilization-outer-wrapper ">
                  <h5 className="para uti-heading flex-centered" style={{ position: "relative" }} >
                    Rental Utilization History
                    {checkPermission('contracts', 'utilization', 'C') && contractInfo?.hdlineInfo?.archived_asset ==false ?
                      <span style={{ position: "absolute", top: "0px", left: "430px" }}>
                        <Link onClick={() => this.setState({ addUtil: true, utilsObj: {} }, () => this.yearMonth('', 'year'))} className="primary-btn caption-ui" style={{ height: "27px", width: "141px" }}>Add Utilization</Link>
                      </span> : null
                    }
                    {checkPermission('contracts', 'utilization', 'EXP') ?
                      <ExportUtilSummery util_type="utilization_history" contract={ctrShortInfo.contract} ctrShortInfo={ctrShortInfo} exportUtils={(contract, type, util_type, aircraft, ctrShortInfo) => this.props.exportRentalUtilsFile(contract, type, util_type, aircraft, removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)), ctrShortInfo)} aircraft={ctrShortInfo} />
                      : null
                    }
                  </h5>
                </div>
                {this.props.params.type !== 'aircraft'?
                <p style={{fontSize:'11px',background:'#FFF8B9',width:'fit-content'}}>NOTE: Older Utilization will not be synced with Monthly Utilization as the back dated entries are not allowed.</p>:null}
                <div className="asset-list-filter-sort">
                  <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={rentalFilterMenu} changeFilterFn={this.changeFilterFn} />
                </div>
                <div className="mobile-asset-list">

                  <div className="asset-listing-wrap utilization-history" style={{ marginLeft: '0px' }} >
                    <div className="asset-list-filter-sort">
                      <Row className="filter-block">
                        <Col><h6>Month</h6></Col>
                        <Col><h6>Hours</h6></Col>
                        <Col><h6>Cycles</h6></Col>
                        <Col><h6>Block Hours</h6></Col>
                        <Col><h6>Remarks</h6></Col>
                        <Col sm={1}><h6 style={{  float: 'right' }}>Action</h6></Col>
                      </Row>
                    </div>
                    {
                      !utilsLoader && rentalUtils && rentalUtils.utilization && rentalUtils.utilization.length ?
                        rentalUtils.utilization.map((rental, index) =>
                          <RentalList contractInfo={contractInfo} getRentalUtilsAttachmentApi={this.getRentalUtilsAttachmentApi} attachedRentalDocList={this.state.attachedRentalDocList} props={this.props} editUtil={() => this.setState({ addUtil: true, month: rental.month.substring(4, 6), year: rental.month.substring(0, 4), utilsObj: { ...rental, type: 'edit' } }, () => this.yearMonth(rental.month.substring(0, 4), 'year'))} rental={rental} index={index} />
                        )
                        : null
                    }
                  </div>
                </div>
                {
                  !utilsLoader && !rentalUtils.utilization.length ? <NoRecordFound style={{ marginLeft: '0px' }} description="Get Started by clicking 'Add Utilization' button above" /> : null
                }
                {
                  !utilsLoader && !rentalUtils.utilization.length ? null :
                    <div style={{ padding: '0 0 40px 0' }}>
                      <Pagination
                        activePage={this.state.rentalPage}
                        itemsCountPerPage={20}
                        totalItemsCount={rentalUtils.pagination.total ? rentalUtils.pagination.total : 0}
                        pageRangeDisplayed={5}
                        onChange={(page) => this.setState({ rentalPage: page }, () => this.props.getRentalUtilization(this.props.params.contract_slug, { page: page }))}

                      />
                    </div>
                }
              </div>
            </TabPane>
          </TabContent>
        </div>
        <Modal isOpen={this.state.addUtil} id="addRentalUtilForm" toggle={() => this.setState({ addUtil: false, error: false })} className="transfer-modal">
          <ModalHeader>
                <ul className="list-inline">
                  <li className="list-inline-item">{this.state.utilsObj.type === 'edit' ? 'Edit' : "Add"} Rental Utilization</li>
                </ul>
          </ModalHeader>
          <ModalBody>
            <Row className="transfer-modal-body">
              <Row className="apps-container">
                <Col md={12}>
                  <ul className="list-inline flex-centered select-util-block">
                    <li className="list-inline-item" style={{ width: '210px' }}>
                      <label className="label">Select Year <sup className="reqStar"> * </sup></label>
                      <Select
                        name="form-field-name"
                        placeholder="Select Year"
                        className="custom-select-block"
                        value={this.state.year}
                        options={years}
                        disabled={this.state.utilsObj.type === 'edit' ? true : false}
                        onChange={(target) => this.yearMonth(target ? target.value : '', 'year')}
                      />
                      {
                        this.state.error.year ?
                          <h6 className="error-msg">{this.state.error.year}</h6>
                          : <h6 className="error-msg">&nbsp;</h6>
                      }
                    </li>
                    <li className="list-inline-item" style={{ width: '210px' }}>
                      <label className="label">Select Month  <sup className="reqStar"> * </sup></label>
                      <Select
                        name="form-field-name"
                        placeholder="Select Month"
                        className="custom-select-block"
                        value={this.state.month}
                        disabled={this.state.utilsObj.type === 'edit' ? true : false}
                        options={this.state.utilsObj.type !== 'edit' ? this.state.months : this.state.month ? [...this.state.months, monthsss.filter(month => month.value === this.state.month)[0]] : this.state.months}
                        onChange={(target) => this.yearMonth(target ? target.value : '', 'month')}
                      />

                      {
                        this.state.error.month ?
                          <h6 className="error-msg">{this.state.error.month}</h6>
                          : <h6 className="error-msg">&nbsp;</h6>
                      }
                    </li>
                  </ul>
                </Col>
                <Col md={12} style={{ marginTop: '20px' }}>
                  <ul className="list-inline flex-centered select-util-block">
                    <li className="list-inline-item" style={{ width: '210px' }}>
                      <label className="label">Hours <sup className="reqStar"> * </sup></label>
                      <div className="form-group">
                        <input
                          type="text"
                          value={this.state.utilsObj.hours}
                          onChange={(e) => this.updateUtilField(e.target.value, 'hours')}
                          style={{ width: '210px', borderBottom:'1px solid #828282'}}
                        />
                        {
                          this.state.error.hours ?
                            <h6 className="error-msg">{this.state.error.hours}</h6>
                            : <h6 className="error-msg">&nbsp;</h6>
                        }
                      </div>
                    </li>
                    <li className="list-inline-item" style={{ width: '210px' }}>
                      <label className="label">Cycles <sup className="reqStar"> * </sup></label>
                      <div className="form-group">
                        <input
                          type="text"
                          value={this.state.utilsObj.cycles}
                          onChange={(e) => this.updateUtilField(e.target.value, 'cycles')}
                          style={{ width: '210px', borderBottom:'1px solid #828282' }}
                        />
                        {
                          this.state.error.cycles ?
                            <h6 className="error-msg">{this.state.error.cycles}</h6>
                            : <h6 className="error-msg">&nbsp;</h6>
                        }
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col md={12} style={{ marginTop: '20px' }}>
                  <ul className="list-inline flex-centered select-util-block">
                    <li className="list-inline-item" style={{ width: '210px' }}>
                      <label className="label">Block Hours {ctrShortInfo.contract && ctrShortInfo.contract.unit_of_measure === 4 ? <sup className="reqStar"> * </sup> : null}</label>
                      <div className="form-group">
                        <input
                          type="text"
                          value={this.state.utilsObj.block_hour ? this.state.utilsObj.block_hour : ''}
                          onChange={(e) => this.updateUtilField(e.target.value, 'block_hour')}
                          style={{ width: '210px', borderBottom:'1px solid #828282' }}
                        />
                        {
                          this.state.error.block_hour ?
                            <h6 className="error-msg">{this.state.error.block_hour}</h6>
                            : <h6 className="error-msg">&nbsp;</h6>
                        }
                      </div>
                    </li>
                    <li className="list-inline-item" style={{ width: '210px' }}>
                      <label className="label">Remarks</label>
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength={255}
                          value={this.state.utilsObj.remarks ? this.state.utilsObj.remarks : ''}
                          onChange={(e) => this.updateUtilField(e.target.value, 'remarks')}
                          style={{ width: '210px', borderBottom:'1px solid #828282' }}
                        />
                        {
                          this.state.error.remarks ?
                            <h6 className="error-msg">{this.state.error.remarks}</h6>
                            : <h6 className="error-msg">&nbsp;</h6>
                        }
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Row>
            <Row className="transfer-modal-footer">
              <Col md="12" style={{marginTop:"30px"}}>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <button type="button" disabled={this.state.saving} onClick={() => this.saveRentalUtils()} style={{ backgroundColor: '#3f51b5', borderColor: '#3f51b5' }} className="btn btn-primary">{this.state.saving ? 'Processing...' : 'Save'}</button>
                  </li>
                  <li className="list-inline-item">
                    <Link style={{ color: '#3f51b5' }} onClick={() => this.setState({ addUtil: false, error: {} })}>CANCEL</Link>
                  </li>

                </ul>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <EditSidebar
          title={utilCrud.type != '' ?
            (utilCrud.type == 'edit') ? 'Edit Utilization' : 'View Utilization' : ' '
          }
          editSideBar={utilCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({ type: '', data: {}, flag: false })} >

          {Object.keys(utilCrud.data).length && utilCrud.flag ?
            <div>
              <EditUtils
                type={utilCrud.type}
                contract_slug={this.props.params.contract_slug}
                utilsObj={utilCrud.data}
                editUtilsInfo={(slug, data) => this.props.editUtilsInfo(slug, data, this.props.assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({ type: '', data: {}, flag: false })} />
            </div>
            : null
          }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  utilsInitiate: state.UtilsReducer.utilsInitiate,
  utilsListInfo: state.UtilsReducer.utilsListInfo,
  utlisAverage: state.UtilsReducer.utlisAverage,
  utilsLoader: state.UtilsReducer.utilsLoader,
  utilsMajorAssembly: state.UtilsReducer.utilsMajorAssembly,
  utilCrud: state.UtilsReducer.utilCrud,
  activeTab: state.UtilsReducer.activeTab,
  rentalUtils: state.UtilsReducer.rentalUtils,
  assetViewId: state.userAssetReducer.assetViewId,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchUtilsInfo: (type) => dispatch(UtilsAssetViewAc(ownProps.params, type)),
    utilizationList: (filterParams) => dispatch(utilsListAc(ownProps, filterParams)),
    // utilsView: (slug,id) => dispatch(utilsViewAc(slug,id)),
    utilsTrends: () => dispatch(utilsTrendsAc(ownProps)),
    utilsAvg: () => dispatch(utilsAvgAc(ownProps)),
    utilsInitiateFn: () => dispatch(utilsInitiateAc(ownProps)),
    utilsMajorAssemblyFn: () => dispatch(utilsMajorAssemblyAc(ownProps)),
    utilFnCrud: (slug, data, type) => dispatch(utilFnCrudAc(slug, data, type)),
    editUtilsInfo: (slug, data, assetViewId) => dispatch(editUtilsInfoAc(slug, data, assetViewId, ownProps.params, ownProps)),
    exportUtilsFile: (contract, fileType, util_type, aircraft, appliedFilter, ctrShortInfo) => dispatch(exportUtilizationAc(ownProps, contract, fileType, util_type, aircraft, appliedFilter, ctrShortInfo)),
    exportRentalUtilsFile: (contract, fileType, util_type, aircraft, appliedFilter, ctrShortInfo) => dispatch(exportRentalUtilizationAc(ownProps, contract, fileType, util_type, aircraft, appliedFilter, ctrShortInfo)),
    updateUtilsGraph: (type, value) => dispatch({
      type: CHANGE_UTIL_GRAPH,
      payload: { type, value }
    }),
    closeUtilsBar: (data) => dispatch({
      type: CLOSE_UTILS_BAR,
      payload: data
    }),
    toggle: (data) => dispatch({
      type: TOGGLE_TAB,
      payload: data
    }),
    toggleEditBar: (data) => dispatch({
      type: UTIL_CRUD_OPERN,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    getRentalUtilization: (filters, flag) => dispatch(getRentalUtilizationAc(ownProps, filters, flag)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Utilization, ['contracts', 'utilization', 'R'])));
