
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import UtilsGraph from '../Utilizations/components/UtilsGraph';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import '../assets/styles/Dashboard.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UtilsAssetViewAc } from '../../contracts/Utilizations/actionCreators';
import { CHANGE_UTIL_GRAPH,  TOGGLE_TAB } from '../../contracts/Utilizations/actions';
class UtilTrendsGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
      }
    componentDidMount() {
        this.props.fetchUtilsInfo('list');
      }

  render() {
    const {ctrShortInfo,utilsTrendsInfo, utlisAverage, activeTab} = this.props;
    const utilsTrends = utilsTrendsInfo.data;
    return (
        <>
        { Object.keys(utilsTrendsInfo.data).length ?
           <div className="util-heading-block" style={{marginLeft:'0px', marginTop:'20px'}}>
             <h5 className="uti-heading margin-bottom-8-xs" style={{ paddingTop: '0' }}>Utilization Trend</h5>
             <div className="edit-view-tech-spec" style={{ margin: 0 }}>
               <Nav tabs>
                 {
                   utilsTrends.airframe ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab === '0' })}
                         onClick={() => this.props.toggle('0')}
                       >
                         Airframe
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.apu ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab === '1' })}
                         onClick={() => this.props.toggle('1')}
                       >
                         APU
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.landing_gears ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab === '2' })}
                         onClick={() => this.props.toggle('2')}
                       >
                         Landing Gears
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.engines ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: this.props.activeTab === '3' })}
                         onClick={() => this.props.toggle('3')}
                       >
                         Engines
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.propeller ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab === '4' })}
                         onClick={() => this.props.toggle('4')}
                       >
                         Propeller
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.fan ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab })}
                         onClick={() => this.props.toggle('5')}
                       >
                         Fan
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.hpc ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab })}
                         onClick={() => this.props.toggle('6')}
                       >
                         HPC
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.hpt ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab })}
                         onClick={() => this.props.toggle('7')}
                       >
                         HPT
                       </NavLink>
                     </NavItem> : null
                 }
                 {
                   utilsTrends.lpt ?
                     <NavItem>
                       <NavLink
                         className={classnames({ active: activeTab})}
                         onClick={() => this.props.toggle('8')}
                       >
                         LPT
                       </NavLink>
                     </NavItem> : null
                 }
               </Nav>
               <TabContent activeTab={activeTab}>
                 {
                   utilsTrends.airframe ?
                     <TabPane tabId='0'>
                       <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name === utilsTrends.airframe.name).length ? utlisAverage.filter(item => item.name === utilsTrends.airframe.name)[0] : {}} hour_plots={utilsTrends.airframe.hour_plots} cycle_plots={utilsTrends.airframe.cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.apu ?
                     <TabPane tabId='1'>
                       <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('apu')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('apu'))[0] : {}} hour_plots={utilsTrends.apu.hour_plots} cycle_plots={utilsTrends.apu.cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.lpt !== undefined ?
                     <TabPane tabId={activeTab}>
                       <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('lpt')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('lpt'))[0] : {}} hour_plots={utilsTrends.lpt.hour_plots} cycle_plots={utilsTrends.lpt.cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.hpc !== undefined ?
                     <TabPane tabId={activeTab}>
                       <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('hpc')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('hpc'))[0] : {}} hour_plots={utilsTrends.hpc.hour_plots} cycle_plots={utilsTrends.hpc.cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.hpt !== undefined ?
                     <TabPane tabId={activeTab}>
                       <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('hpt')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('hpt'))[0] : {}} hour_plots={utilsTrends.hpt.hour_plots} cycle_plots={utilsTrends.hpt.cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.fan !== undefined ?
                     <TabPane tabId={activeTab}>
                       <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('fan')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('fan'))[0] : {}} hour_plots={utilsTrends.fan.hour_plots} cycle_plots={utilsTrends.fan.cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.landing_gears ?
                     <TabPane tabId='2'>
                       <ul className="list-inline lg-checkbox" style={{ marginBottom: '10px' }}>
                         {
                           Object.keys(utilsTrends.landing_gears).map((label, index) =>
                             <li className="list-inline-item" key={index}>
                               <label>
                                 <input
                                   type="radio"
                                   name="lg"
                                   checked={label == utilsTrendsInfo.lgSelected ? true : false}
                                   onChange={() => this.props.updateUtilsGraph('lgSelected', label)}
                                 />
                                 <span>{utilsTrends.landing_gears[label].name} </span></label>
                             </li>
                           )
                         }
                       </ul>
                       <UtilsGraph avgData={utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].name.toLowerCase())).length ? utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].name.toLowerCase()))[0] : {}} ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].hour_plots} cycle_plots={utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.propeller ?
                     <TabPane tabId='4'>
                       <ul className="list-inline lg-checkbox" style={{ marginBottom: '10px' }}>
                         {
                           Object.keys(utilsTrends.propeller).map((label, index) =>
                             <li className="list-inline-item" key={index}>
                               <label>
                                 <input
                                   type="radio"
                                   name="propeller"
                                   checked={label == utilsTrendsInfo.propellerSelected ? true : false}
                                   onChange={() => this.props.updateUtilsGraph('propellerSelected', label)}
                                 />
                                 <span>{utilsTrends.propeller[label].name}</span></label>
                             </li>
                           )
                         }
                       </ul>
                       <UtilsGraph avgData={utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.propeller[utilsTrendsInfo.propellerSelected].name.toLowerCase())).length ? utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.propeller[utilsTrendsInfo.propellerSelected].name.toLowerCase()))[0] : {}} ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.propeller[utilsTrendsInfo.propellerSelected].hour_plots} cycle_plots={utilsTrends.propeller[utilsTrendsInfo.propellerSelected].cycle_plots} />
                     </TabPane> : null
                 }
                 {
                   utilsTrends.engines ?
                     <TabPane tabId='3'>
                       <ul className="list-inline lg-checkbox" style={{ marginBottom: '10px' }}>
                         {
                           Object.keys(utilsTrends.engines).map((label, index) =>
                             <li className="list-inline-item" key={index}>
                               <label>
                                 <input
                                   type="radio"
                                   name="engine"
                                   checked={label == utilsTrendsInfo.engineSelected ? true : false}
                                   onChange={() => this.props.updateUtilsGraph('engineSelected', label)}
                                 />
                                 <span>{utilsTrends.engines[label].name}</span></label>
                             </li>
                           )
                         }
                       </ul>
                       <UtilsGraph avgData={utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.engines[utilsTrendsInfo.engineSelected].name.toLowerCase())).length ? utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.engines[utilsTrendsInfo.engineSelected].name.toLowerCase()))[0] : {}} ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.engines[utilsTrendsInfo.engineSelected].hour_plots} cycle_plots={utilsTrends.engines[utilsTrendsInfo.engineSelected].cycle_plots} />
                     </TabPane> : null
                 }
               </TabContent>
             </div></div>
             :null
         }
         </>
    )
  }
}
const mapStateToProps = state => ({
    ctrShortInfo: state.ContractListReducer.ctrShortInfo,
    techSpecsLoader: state.TechSummaryReducer.techSpecsLoader,
    utilsTrendsInfo: state.UtilsReducer.utilsTrendsInfo,
    utlisAverage: state.UtilsReducer.utlisAverage,
    activeTab: state.UtilsReducer.activeTab,
  });
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchUtilsInfo: (type) => dispatch(UtilsAssetViewAc(ownProps.params, type)),
      updateUtilsGraph: (type, value) => dispatch({
        type: CHANGE_UTIL_GRAPH,
        payload: { type, value }
      }),
      toggle: (data) => dispatch({
        type: TOGGLE_TAB,
        payload: data
      })
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UtilTrendsGraph))
