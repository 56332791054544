import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const InvoiceStatus = ({ invoiceSummary }) => {
  var colors = ['#89BFFF', '#f5db36', '#a6d186', '#9DEC54','#F94E35',];
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Invoice Status Summary',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        xAxis: {
            categories: invoiceSummary && invoiceSummary.invoice_status && invoiceSummary.invoice_status.x_axis && invoiceSummary.invoice_status.x_axis.categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Number of Invoices'
            }
        },
        colors: colors,
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> <br/>'
        },
        legend: {
            enabled: false
        },
        series: {
            name: 'Invoices',
            data: invoiceSummary && invoiceSummary.invoice_status && invoiceSummary.invoice_status.y_axis,
            colorByPoint: true,
        },
        credits: {
            enabled: false
        }
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
export default InvoiceStatus
